<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<!-- 搜索框 -->
			<div class="grabble">
				<div style="width: 70%;display: flex;">
					<el-input class="hunt" v-model="topic" placeholder="请输入标题" size="large" @input="orangutan" clearable />
					<el-select v-model="values" placeholder="请选择类型" clearable @clear="empty" size="large">
						<el-option label="通知公告" value="1" style="width:100%; text-align-last: center;"></el-option>
						<el-option label="最新咨询" value="2" style="width:100%; text-align-last: center;"></el-option>
						<el-option label="规章制度" value="3" style="width:100%; text-align-last: center;"></el-option>
					</el-select>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="search1"
						:loading='formative'><el-icon class="cancelgu">
							<Search />
						</el-icon>搜索</el-button>
				</div>
				<div>
					<el-button style="margin-left: 10px" type="primary" size="large" @click="append"><el-icon
							class="cancelgu">
							<Plus />
						</el-icon>添加</el-button>
				</div>
			</div>
			<div class="summation">
				<div class="hint">统计 : {{altogether}}条</div>
				<div class="hint">提示 : 动态只有发布人所管理工会会员可见，其他不可见。</div>
			</div>
			<el-table :data="filterTableData" style="width: 100%" row-key="unionId" @cell-mouse-enter="obtain" border
				:empty-text="texts" :header-cell-style="{ background: '#F5F7FA' }" @row-click="unit">
				<!-- 动态分类 -->
				<el-table-column align="center" label="动态分类" prop="type" :formatter="formatSex"
					:show-overflow-tooltip='true' />
				<!-- 标题 -->
				<el-table-column label="标题" prop="title" align="center" :show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 时间 -->
				<el-table-column label="创建时间" prop="time" align="center" :formatter="tiems"
					:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 发布人 -->
				<el-table-column label="发布人" prop="name" align="center" :formatter="quantum"
					:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 所在组织 -->
				<el-table-column label="所属工会" prop="unionName" align="center" :formatter="quantum"
					:show-overflow-tooltip='true'>
				</el-table-column>
				<!-- 状态 -->
				<el-table-column label="状态" prop="status" align="center" :formatter="condition"
					:show-overflow-tooltip='true'>
				</el-table-column>
				<el-table-column label="操作" align="center" width="240">
					<template #default="scope">
						<el-button size="default" @click.stop="copyreader(scope.row.newId)" class="bulur"><el-icon
								class="cancel">
								<EditPen />
							</el-icon>编辑</el-button>
						<el-button size="default" @click.stop="expurgate(scope.row.newId)" class="red"><el-icon
								class="cancel">
								<Delete />
							</el-icon>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 表格分页 -->
			<!-- 一页时候隐藏 hide-on-single-page="true" -->
			<el-pagination hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
				v-model:currentPage="currentPage" :page-size="pagesize" :total="altogether"
				@size-change="handleSizeChange" @current-change="handleCurrentChange" />
		</el-card>
		<!-- 删除按钮弹窗 -->
		<el-dialog v-model="remove" title="动态删除" width="30%" center>
			<div class="labor">
				<div class="sure">确定删除此动态吗？</div>
			</div>
			<template #footer>
				<span class="dialog-footer">
					<el-button class="asd" size="large" type="primary" @click="affirm">确定</el-button>
					<el-button class="dsa" size="large" @click="remove=false">取消</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>
<script>
	import {
		Delete,
		EditPen,
		Search,
		Plus
	} from "@element-plus/icons-vue";
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		ref,
		onMounted,
		onActivated
	} from 'vue';
	import {
		Statelist,
		GHdelete
	} from '../../utils/api'
	import qs from 'qs'
	import moments from 'moment'
	import {
		ElMessage
	} from "element-plus";
	export default {
		name: "dynamic",
		setup() {
			const route = useRoute();
			// 搜索加载
			const formative = ref(false)
			// 表格每行点击
			let unit = (row) => {
				console.log(row)
				sessionStorage.setItem('newsid', row.newId)
				router.push('/DTdetails')
				console.log('点击了')
			}
			// 删除功能
			let remove = ref(false)
			let affirm = () => {
				let data = {
					newId: newsid.value
				}
				GHdelete(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						ElMessage({
							message: '删除成功',
							type: "success",
							center: true,
						});
						if (topic.value !== '' || values.value !== '') {
							search()
						} else {
							list()
						}
						remove.value = false
					} else {
						ElMessage({
							message: res.data.msg,
							type: "warning",
							center: true,
						});
						if (topic.value !== '' || values.value !== '') {
							search()
						} else {
							list()
						}
						remove.value = false
					}
				})
			}
			// 获取删除id
			let newsid = ref()
			let expurgate = (id) => {
				remove.value = true
				newsid.value = id
				console.log(id)
			}
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.userId
				if (translate.value == 1) {
					values.value = ''
					topic.value = ''
					texts.value = '加载中'
					// branches.value = 6
					// currentPage.value = 1
					list()
				}
				// console.log(translate.value)
				// console.log(route.params.userId)
			})
			onMounted(() => {
				list()
			})
			// 列表内容显示
			let texts = ref('加载中')
			// 搜索框值变化清空
			let orangutan = () => {
				if (topic.value == '' && values.value == '') {
					list()
				}
			}
			// 下拉清空
			let empty = () => {
				if (topic.value == '' && values.value == '') {
					list()
				}
			}
			// 搜索
			let topic = ref('')
			let search1 = () => {
				formative.value = true
				search()
			}
			let search = () => {
				console.log(topic.value)
				console.log(values.value)
				let data = {
					limit: branches.value,
					page: currentPage.value,
					title: topic.value,
					type: values.value
				}
				console.log(data)
				Statelist(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						formative.value = false
						altogether.value = res.data.data.page.totalCount
						filterTableData.value = res.data.data.page.list
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}

					}
				})
			}
			// 分页
			let currentPage = ref(1)
			let pagesize = ref(6)
			let handleSizeChange = (size) => {
				pagesize.value = size
				branches.value = size
				if (topic.value !== '' || values.value !== '') {
					search()
				} else {
					list()
				}
			}
			let handleCurrentChange = (size) => {
				currentPage.value = size
				// branches.value+=50
				if (topic.value !== '' || values.value !== '') {
					search()
				} else {
					list()
				}
			}
			// 发布状态
			let condition = (row, colum, cellValue) => {
				if (cellValue == 1) {
					return "已发布";
				} else if (cellValue === 2) {
					return "未发布";
				}
			}
			// 时间转换
			let tiems = (row, colum, cellValue) => {
				if (typeof cellValue == 'number') {
					return moments(cellValue * 1000).format().substring(0, 10)
				}
			}
			// 判断类型
			let formatSex = (row, column, cellValue) => {
				if (cellValue == 1) {
					return "通知公告";
				} else if (cellValue === 2) {
					return "最新资讯";
				} else if (cellValue === 3) {
					return "规章制度";
				}
			};
			// 总条数
			let altogether = ref(null)
			// 工会动态列表
			let branches = ref(6)
			const list = () => {
				let data = {
					limit: branches.value,
					page: currentPage.value
				}
				Statelist(qs.stringify(data)).then((res) => {
					console.log(res)
					if (res.data.code == 0) {
						altogether.value = res.data.data.page.totalCount
						filterTableData.value = res.data.data.page.list
						if (filterTableData.value.length == 0) {
							texts.value = '暂无数据'
						}
					}
				})
			}
			// 头部搜索
			let values = ref('')
			// 编辑跳转
			const copyreader = (id) => {
				console.log(id)
				router.push({
					name: 'dtedit',
					params: {
						UserId: 1
					}
				});
				sessionStorage.setItem('new', id)
			}
			// 路由跳转
			const router = useRouter()
			// 头部添加跳转
			let append = () => {
				router.push('/inform')
			}
			// 列表数据循环
			const filterTableData = ref([])
			return {
				// 搜索加载
				search1,
				formative,
				unit,
				affirm,
				remove,
				expurgate,
				texts,
				orangutan,
				empty,
				// 搜索
				search,
				topic,
				// 分页
				handleSizeChange,
				handleCurrentChange,
				altogether,
				pagesize,
				currentPage,
				// 表格数据
				condition,
				tiems,
				formatSex,
				values,
				// 编辑跳转
				copyreader,
				// 头部组织添加跳转
				append,
				// 循环列表数据
				filterTableData
			};
		},
		components: {
			Delete,
			EditPen,
			Search,
			Plus
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .dsa {
		margin-left: 10px !important;
	}

	::v-deep .asd {
		margin-right: 10px !important;
	}

	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	.chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	.summation {
		display: flex;
		justify-content: space-between;
	}

	::v-deep .el-table__empty-block {
		border-bottom: 1px solid #EBEEF5 !important;
	}

	.hint {
		font-size: 14px;
		color: #909399;
		// font-weight: bold;
		margin-bottom: 15px;
	}

	.el-select--large {
		width: 20%;
		// margin-left: 10px;
	}

	::v-deep .el-table__body {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	::v-deep .el-table__header {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}

	.hunt {
		width: 20%;
		margin-right: 10px;
	}

	.grabble {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 15px;
		display: flex;
		justify-content: space-between;
	}

	.el-table__header {
		margin-top: 0;
		margin-bottom: 0;
	}

	.red {
		color: red;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.red:hover {
		color: #fab6b6;
	}

	.bulur {
		color: #409eff;
		border: none;
		--el-button-bg-color: none;
		--el-button-hover-bg-color: none;
		--el-button-hover-text-color: none;
	}

	.bulur:hover {
		color: #a0cfff;
	}

	// .el-button{
	//   border: none;
	//   --el-button-bg-color:none;
	//   --el-button-hover-bg-color:none;
	//   --el-button-hover-text-color:none;
	// }
	.el-scrollbar__thumb {
		background-color: #ffffff;
	}

	.items {
		display: flex;
	}

	.el-select .el-input__inner {
		width: 183px;
	}

	::v-deep .el-dialog--center .el-dialog__body {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	::v-deep .el-select-dropdown__empty {
		display: none !important;
	}

	.el-select-dropdown__item {
		background-color: #ffffff;
		height: 40px !important;
	}

	.el-select-dropdown__item {
		overflow: visible;
	}

	.labor {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tianjia {
		margin-left: 20px;
	}

	.cancel {
		margin-right: 5px;
		font-weight: 400;
	}

	.cancelgu {
		margin-right: 6px;
		font-weight: bold;
	}

	::v-deep .el-table__inner-wrapper::before {
		width: 0;
	}

	::v-deep .el-table .el-table__cell {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.el-card.is-always-shadow {
		width: 95%;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	.el-pagination {
		margin-top: 10px;
	}

	::v-deep .el-input--small .el-input__inner {
		height: 30px;
		line-height: 30px;
	}

	.tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
	}
</style>
<style>
	/* ::-webkit-scrollbar{display:none} */
	.el-popper {
		z-index: 10000 !important;
	}

	.modality {
		height: 150px !important;
	}

	/* .el-scrollbar__thumb {
  display: none !important;
} */
	.el-select-dropdown__item.selected {
		font-weight: 500 !important;
	}
</style>